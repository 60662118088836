<template>
    <div class="box box-primary">
     <div class="box-header with-border">
       <i class="fa fa-calendar"></i>
       <h3 class="box-title text-right">Calendar Configuration</h3>
     </div>
     <div class="box-body">
           <div class="box-content box-bg-color">
               
               <div class="row">
                    <div class="col-md-6">
                       <div class="box box-info">
                           <div class="box-header with-border">
                               <h3 class="box-title">Calendar Settings</h3>
                           </div>
                           <div class="box-body">
                               <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label text-left">Calendar Name</label>
                                                <div class="s">
                                                        <input type="text" class="form-control" name="name">
                                                </div>
                                            </div>

                                            <div class="col-md-6 form-group">
                                                    <label class="control-label text-left">
                                                        Time Zone
                                                    </label>
                                                    <div>
                                                        <Multiselect
                                                            v-model="value"
                                                            placeholder="-Select Timezone-"
                                                            label="name"
                                                            :searchable="false"
                                                            :options="[
                                                                { value: 'IST', name: 'IST',level:'1'}
                                                            ]"
                                                            >
                                                            <template v-slot:option="{ option }">
                                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                            </template>
                                                        </Multiselect>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group"><label class="control-label text-left">Calendar Name</label>
                                                <textarea class="form-control"></textarea>
                                            </div>
                                        </div>  
                                     </div>
                               </div>
                               
                           </div>
                          
                       </div>
                   </div>
                  
                    </div>
                    <div class="col-md-6">
                            <div class="box box-info overlay-wrapper">
                                <div class="box-header with-border">
                                    <h3 class="box-title">Linked Users</h3>
                                </div>
                                <div class="box-body">
                                    <p>Linked users have the ability to read calendar events directly from the Google Calendar, and can sync the events to their own calendar.
                                        </p> 
                                    <div class="row">
                                        <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="control-label text-left">Email Address</label>
                                                    <div class="input-group mbottom-15">
                                                        <input type="text" class="form-control" placeholder="Email Address">
                                                        <span class="input-group-btn"><button type="button" class="btn btn-info btn-flat">Add</button></span>
                                                    </div>
                                                </div>
                                                    <div class="form-group">
                                                        <label class="control-label text-left">User List</label>
                                                        <div class="domain-list">
                                                            <div class="list-item ng-scope"><button class="icon-button button-delete" type="button"><i class="fa fa-times-circle"></i></button><span>1to1.iservicecrm.com</span></div>
                                                    </div>
                                                </div> 
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
               </div>
               <div class="row">
                <div class="col-md-6">
                    <div class="nested-panel-heading skill-heading">
                        <h3 class="nested-title">Places that use this calendar</h3>
                    </div>
                    <div class="skill-list">
                       <table class="table ">
                         <thead class="bg-gray text-dark">
                            <tr>
                             <th>Name</th>
                             <th>ID</th>
                             <th>Page URL</th>
                            </tr>
                         </thead>
                         <tbody>
                            <tr>
                             <th>Field Service Management</th>
                             <th><a href="">11</a></th>
                             <th><a href="">Link to Calendar</a></th>
                            </tr>
                         </tbody>
                        </table>
                    </div>
                </div>

                </div>
               <div class="row">
                    <div class="col-md-12 col-12"><button class="btn btn-primary pull-left">Save</button></div>
                </div>
   
           </div>
     </div>
   </div>
   </template>
   
   <style src="@vueform/toggle/themes/default.css"></style>
   <script>
   import Multiselect from '@vueform/multiselect'
   import Vue from 'vue';
   import Toggle from '@vueform/toggle'
   import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
   import Variables from '../components/Variables.vue';
   import { reactive,ref, onMounted } from 'vue'
   export default {
     name: 'Calendar Configuration Details',
      components: {
       Variables,
       VariableAndStockResponses,
       Multiselect,
     },
     setup(){      
         
          return{};
     }
   
   }
   </script>
   